<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="addSupplierModal()">Agregar Vendedor</CButton>
          <CButton color="success" size="sm" @click="importModal()">Importar Vendedores</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <div role="group" class="form-group manager_filter" style="float: right; margin: 0px 0px 0px; font-size: 12px;">
          <CSelect
            :value.sync="managerSelected" 
            :options="managersOptions"
            @change="changeFilters"
            style="font-size: 10px;"
          />            
      </div>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
        v-if="items.length > 0">
      
        <template #route="{item}"><td class="w200 text-center"><span>{{item.route ? item.route : '-'}}</span></td></template>
        <template #supplier_name="{item}"><td class="text-center"><span>{{item.supplier_name ? item.supplier_name : '-'}}</span></td></template>
        <template #actions="{item}">
          <td class="table_actions b3">
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
      <div style="width: 100%; text-align: center;" v-if="items.length == 0 && !this.managerSelected"><br />NO HAY VENDEDORES REGISTRADOS<br /><br /><b>TODOS LOS VENDEDORES TENDRÁN ACCESO AL CONCURSO</b><br /><br /></div>
      <div style="width: 100%; text-align: center;" v-if="items.length == 0 && this.managerSelected"><br />EL SUPERVISOR NO TIENE VENDEDORES REGISTRADOS<br /><br /></div>
    </CCardBody>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
    <ImportSuppliersModal ref="importModal" @import="importProcess"></ImportSuppliersModal>
    <ChallengeSupplierModal ref="supplierModal" @store="storeProcess"></ChallengeSupplierModal>
  </CCard>
</template>

<script>
import DeleteModal from '../global/DeleteModal.vue'
import ImportSuppliersModal from './ImportSuppliersModal.vue'
import ChallengeSupplierModal from './ChallengeSupplierModal.vue'
import ws from '../../services/challenges';
import ws_managers from '../../services/managers';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeAvailable',
  components: { DeleteModal, ImportSuppliersModal, ChallengeSupplierModal },
  props: {
    items: Array,
    permissions: Object,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'route', label: 'Ruta'},
          {key: 'supplier_name', label: 'Nombre'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    challenge: {
      type: Object,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      },
      managers: [],
      managersOptions: [],
      managerSelected: "",
    }
  },
  mounted: async function() {
    let response = await ws_managers.get(); 

    if(response.type == "success"){
      this.managers = response.data;

      this.managersOptions = await this.$parseSelectOptionsOrdered(this.managers, "id", "name", [{value: '0', label: 'Todos los Supervisores'}]);
    }
  },
  methods: {
    deleteModal (item) {
      this.$refs.deleteModal.show("Quitar Vendedor", item.id, item.supplier_name);
    },
    async deleteProcess(id) {
      this.loading();
      let response = await ws.deleteAvailable({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
      this.showToast(response.type, response.message);
    },
    addSupplierModal () {
      this.$refs.supplierModal.addSupplierModal(this.challenge.id);
    },
    async storeProcess(data) {
      this.loading();
      let response = await ws.addSupplier(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
      this.showToast(response.type, response.message);
    },
    importModal () {
      this.$refs.importModal.importModal(this.challenge.id);
    },
    async importProcess(data) {
      this.$emit("refresh", data );
    },
    async changeFilters () {
      this.loading();

      let response = await ws.getAvailablesByManager(this.challenge.id, this.managerSelected);

      if(response.type == "success"){
        this.$emit("filtered", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        provider : this.providerSelected
      };

      return filters;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
