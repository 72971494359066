<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <div role="group" class="form-group manager_filter" style="float: right; margin: 0px 0px 0px; font-size: 12px;">
          <CSelect
            :value.sync="cedisSelected" 
            :options="cedisOptions"
            @change="changeFilters"
            style="font-size: 10px;"
          />            
      </div>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
        v-if="items.length > 0"
      >
        <template #supplier_code="{item}"><td class="w150 text-center"><span>{{item.supplier_code}}</span></td></template>
        <template #supplier_cedis="{item}"><td class="w200 text-center"><span>{{item.supplier_cedis}}</span></td></template>
        <template #points="{item}"><td class="w150 text-center"><span>{{item.points}}</span></td></template>
        <template #date="{item}"><td class="w200 text-center"><span>{{item.date | date}} ({{item.date.substring(11,16)}})</span></td></template>
        <template #files="{item}"><td class="text-left">
          <span>
            <ul style="margin: 0px"><li v-for="file in item.files"><a :href="file.answer" target="_blank">{{file.question}}</a></li></ul>
          </span>
        </td></template>
        <template #paid="{item}">
          <td class="table_actions b1">
            <CButton :color="item.paid ? 'success' : 'danger'" size="sm" @click="item.paid ? unpaid(item) : paid(item)"><CIcon :name="item.paid ? 'cil-check' : 'cil-clock'"/></CButton>
          </td>
        </template>
        <template #actions="{item}">
          <td class="table_actions b4">
            <CButton v-if="item.supplier_user_id > 0" color="success" size="sm" @click="login(item)"><CIcon name="cil-https"/> Ingresar</CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
      <div style="width: 100%; text-align: center;" v-if="items.length == 0"><br />AÚN NO SE HAN REGISTRADO PARTICIPACIONES A ESTE CONCURSO<br /><br /></div>
    </CCardBody>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/challenges';
import ws_cedis from '../../services/cedis';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeEntries',
  components: { DeleteModal },
  props: {
    items: Array,
    permissions: Object,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'supplier_code', label: 'Ruta'},
          {key: 'supplier_name', label: 'Nombre'},
          {key: 'date', label: 'Fecha'},
          {key: 'files', label: 'Archivos'},
          {key: 'date', label: 'Pagado'},
          {key: 'points', label: 'Puntos'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    challenge: {
      type: Object,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      },
      cedis: [],
      cedisOptions: [],
      cedisSelected: "",
    }
  },
  mounted: async function() {
    let _this = this;
    setTimeout(async function(){
      let response = await ws.getCedis(_this.challenge.id); 

      if(response.type == "success"){
        _this.cedis = response.data;

        _this.cedisOptions = await _this.$parseSelectOptionsOrdered(_this.cedis, "id", "name", [{value: '0', label: 'Todos los CEDIS'}]);
      }
    }, 1000);
  },
  methods: {
    async changeFilters () {
      this.loading();

      let response = await ws.getEntriesByCedis(this.challenge.id, this.cedisSelected);

      if(response.type == "success"){
        this.$emit("filtered", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        cedis : this.cedisSelected
      };

      return filters;
    },
    login (item) {
      window.location = "https://app.kpibonus.com/#/"+item.user.api_token+"/login-link";
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Participación", item.id, item.supplier_name);
    },
    async deleteProcess(id) {
      let response = await ws.deleteEntry({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async paid(item) {
      let response = await ws.paidEntry({id: item.id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async unpaid(item) {
      let response = await ws.unpaidEntry({id: item.id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
