<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >
        <CForm>
            <CRow>
              <CCol md="12">
                <b>¿Estás seguro de que deseas reiniciar el concurso?</b>
                <br />
                <br />
                <i>Este proceso eliminará todas las ventas y vendedores participantes al concurso.</i>
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="reset" :color="color">Reiniciar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/customers';
import suppliers_ws from '../../services/suppliers';

export default {
  name: 'ChallengeSupplierModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Reiniciar Concurso",
      color: "danger",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    showResetModal (challenge_id) { 
      this.showModal = true;
      this.color = "danger";
      this.title = 'Reiniciar Concurso';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          challenge_id: challenge_id
      }
    },
    reset () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("reset", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          challenge_id: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {

    }
  },
}
</script>