<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="addCustomerModal()">Agregar Cliente</CButton>
          <CButton color="success" size="sm" @click="importModal()">Importar Clientes</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
        v-if="items.length > 0">
      
        <template #code="{item}"><td class="w200 text-center"><span>{{item.code ? item.code : '-'}}</span></td></template>
        <template #name="{item}"><td class="text-center"><span>{{item.name ? item.name : '-'}}</span></td></template>
        <template #actions="{item}">
          <td class="table_actions b3">
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
      <div style="width: 100%; text-align: center;" v-if="items.length == 0"><br />NO HAY CLIENTES REGISTRADOS<br /><br /><b>TODOS LOS CLIENTES PARTICIPAN PARA EL CALCULO DE PREMIOS</b><br /><br /></div>
    </CCardBody>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
    <ImportCustomersModal ref="importModal" @import="importProcess"></ImportCustomersModal>
    <ChallengeCustomerModal ref="customerModal" @store="storeProcess"></ChallengeCustomerModal>
  </CCard>
</template>

<script>
import DeleteModal from '../global/DeleteModal.vue'
import ImportCustomersModal from './ImportCustomersModal.vue'
import ChallengeCustomerModal from './ChallengeCustomerModal.vue'
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengeAvailable',
  components: { DeleteModal, ImportCustomersModal, ChallengeCustomerModal },
  props: {
    items: Array,
    permissions: Object,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'code', label: 'Código'},
          {key: 'name', label: 'Nombre'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    challenge: {
      type: Object,
      default: 0
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  mounted: async function() {

  },
  methods: {
    deleteModal (item) {
      this.$refs.deleteModal.show("Quitar Cliente", item.id, item.supplier_name);
    },
    async deleteProcess(id) {
      let response = await ws.deleteCustomer({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    addCustomerModal () {
      this.$refs.customerModal.addCustomerModal(this.challenge.id);
    },
    async storeProcess(data) {
      let response = await ws.addCustomer(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    importModal () {
      this.$refs.importModal.importModal(this.challenge.id);
    },
    async importProcess(data) {
      this.$emit("refresh", data );
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
