<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Premios del Concurso
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-for="(award, index) in challenge.awards" :key="'r_'+index">
        <CCol>
          <CRow>
            <CCol md="12" v-if="challenge.type == 'video'"><b>Ver Video: </b> Cuando el vendedor visualice el video, se otorgará el siguiente premio: <br /><br /></CCol>
            <CCol md="12" v-if="challenge.type == 'boxes_and_customers' && award.mechanic == 'boxes'"><b>Venta de Cajas: </b> Cada vez que el vendedor obtenga logré vender <b>{{award.boxes}} cajas</b>, se otorgará el siguiente premio: <br /><br /></CCol>
            <CCol md="12" v-if="challenge.type == 'boxes_and_customers' && award.mechanic == 'customers'"><b>Venta de Cajas a Clientes: </b> Cada vez que el vendedor obtenga logré vender mínimo <b>{{award.boxes}} cajas</b> a alguno de los clientes participantes, se otorgará el siguiente premio: <br /><br /></CCol>
            <CCol md="12" v-if="challenge.type == 'boxes' && challenge.mechanic == 'total'"><b>Venta de Cajas: </b> Cuando el vendedor logré de vender <b>{{award.boxes}} cajas</b>, se otorgará el siguiente premio: <br /><br /></CCol>
            <CCol md="12" v-if="challenge.type == 'boxes' && challenge.mechanic == 'individual'"><b>Venta de Cajas: </b> Cada vez que el vendedor logré de vender <b>{{award.boxes}} cajas</b>, se otorgará el siguiente premio: <br /><br /></CCol>
            <CCol md="12" v-if="challenge.type == 'boxes' && challenge.mechanic == 'individual'"><b>Venta de Cajas: </b> Cuando el vendedor alcance alguno de los rangos, se otorgará el premio correspondiente: <br /><br /></CCol>
          </CRow>
          <CRow v-if="award.mechanic == 'boxes'">
            <CCol md="3">
              <CInput
                type="number"
                label="Gana (cantidad):"
                :lazy="false"
                :value.sync="main_award_points"
                placeholder=""
              />
            </CCol>
            <CCol :md="main_award_type == 'digital_points' ? 3 : 6">
              <CSelect
                label="Tipo de Premio: "
                id="rate"
                name="rate"
                :value.sync="main_award_type"
                :options="typeOptions"
              />
            </CCol>
            <CCol :md="3" v-if="main_award_type == 'digital_points'">
              <CInput
                label="Alias de los puntos:"
                :lazy="false"
                :value.sync="main_award_alias"
                placeholder=""
              />
            </CCol>
            <CCol :md="3">
              <CInput
                label="Disponibilidad (0 = Ilimitado):"
                :lazy="false"
                :value.sync="main_award_quantity"
                placeholder=""
              />
            </CCol>
            <CCol :md="12">
              <CInput
                label="Descripción del Premio:"
                :lazy="false"
                :value.sync="main_award_description"
                placeholder=""
              />
            </CCol>
          </CRow>
          <CRow v-if="award.mechanic == 'customers'">
            <CCol md="3">
              <CInput
                type="number"
                label="Gana (cantidad):"
                :lazy="false"
                :value.sync="secondary_award_points"
                placeholder=""
              />
            </CCol>
            <CCol md="6">
              <CSelect
                label="Tipo de Premio: "
                id="rate"
                name="rate"
                :value.sync="secondary_award_type"
                :options="typeOptions"
              />
            </CCol>
            <CCol :md="3">
              <CInput
                label="Disponibilidad (0 = Ilimitado):"
                :lazy="false"
                :value.sync="secondary_award_quantity"
                placeholder=""
              />
            </CCol>
            <CCol :md="12">
              <CInput
                label="Descripción del Premio:"
                :lazy="false"
                :value.sync="secondary_award_description"
                placeholder=""
              />
            </CCol>
          </CRow>
          <CRow v-if="challenge.type == 'boxes' && (challenge.mechanic == 'ranges')">
            <CCol md="12"><br /><b>Rangos de Premios: </b> Para este concurso se definieron los siguientes rangos: <br /><br /></CCol>
          </CRow>
          <CRow v-if="challenge.type == 'boxes' && (challenge.mechanic == 'ranges')" v-for="(range, index) in challenge.ranges" :key="'k_'+index">
            <CCol md="2">
              <CInput
                type="number"
                label="Desde (cajas):"
                :lazy="false"
                :value.sync="range.min"
                placeholder=""
                 :key="'min_'+index"
              />
            </CCol>
            <CCol md="2">
              <CInput
                type="number"
                label="Hasta (cajas):"
                :lazy="false"
                :value.sync="range.max"
                placeholder=""
                 :key="'max_'+index"
              />
            </CCol>
            <CCol md="2">
              <CInput
                type="number"
                label="Gana (cantidad)::"
                :lazy="false"
                :value.sync="range.award"
                placeholder=""
                 :key="'award_'+index"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Descripción del Rango:"
                :lazy="false"
                :value.sync="range.description"
                placeholder=""
                 :key="'d_'+index"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="9"></CCol>
            <CCol md="3">
              <CButton style="float: right;" color="info" size="sm" @click="updateAwards(award)">Guardar</CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/challenges';
import store from '../../store'
import router from '../../router/index'
import ChallengeVideoModal from '../../components/challenges/ChallengeVideoModal.vue'
import DeleteModal from '../global/DeleteModal.vue'

export default {
  name: 'ChallengeAwards',
  components: { ChallengeVideoModal, DeleteModal },
  props: {
    items: Array,
    permissions: Object,
  },
  data() {
    return {
      toasts: [],
      challenge: {
        'type': 'boxes'
      },
      awards: [],
      rate: 100,
      typeOptions: [
        {label: 'Puntos Digitales', value: 'digital_points'},
        {label: 'Monedero Electrónico', value: 'wallet'},
        {label: 'Boletos para Evento', value: 'ticket'},
        {label: 'Tarjeta de Regalo', value: 'card'},
        {label: 'Premio Físico', value: 'gift'},
        {label: 'Puntos para Eventos', value: 'event_points'},
        {label: 'Puntos para Certificados', value: 'card_points'},
        {label: 'Puntos para Premios', value: 'gift_points'},
        {label: 'Puntos para Monedero', value: 'wallet_points'}
      ],
      main_award_type: 'event_points',
      main_award_description: '',
      main_award_award: 100,
      main_award_quantity: 0,
      main_award_points: 0,
      main_award_boxes: 0,
      main_award_alias: 'Gol|Goles',
      secondary_award_type: 'event_points',
      secondary_award_description: '',
      secondary_award_award: 100,
      secondary_award_quantity: 0,
      secondary_award_points: 0,
      secondary_award_boxes: 0,
      secondary_award_alias: 'Gol|Goles',
      total: {
        description: '',
        boxes: 10,
        award: 100
      },
      individual: {
        description: '',
        boxes: 10,
        award: 100
      },
      ranges: [
        {
          min: 1,
          max: 5,
          qty: 500,
          description: ""
        },
        {
          min: 6,
          max: 10,
          qty: 1200,
          description: ""
        },
        {
          min: 11,
          max: 20,
          qty: 2500,
          description: ""
        },
        {
          min: 21,
          max: 50,
          qty: 5000,
          description: ""
        },
        {
          min: 51,
          max: 1000,
          qty: 5000,
          description: ""
        }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    async loadChallenge (challenge, awards) {
      this.challenge = challenge;
      this.awards = awards;

      let _this = this;
      setTimeout(function(){
        _this.main_award_type = _this.awards[0].type !== undefined ? _this.awards[0].type : 'event_points';
        _this.main_award_description = _this.awards[0].description;
        _this.main_award_quantity = (_this.awards[0].type == 'event_points' || _this.awards[0].type == 'card_points' || _this.awards[0].type == 'gift_points' || _this.awards[0].type == 'wallet_points' || _this.awards[0].type == 'digital_points') ? 0 : _this.awards[0].quantity;
        _this.main_award_points = _this.awards[0].points;
        _this.main_award_boxes = _this.awards[0].boxes;
        _this.main_award_alias = _this.awards[0].alias;

        _this.secondary_award_type = _this.awards[1].type !== undefined ? _this.awards[0].type : 'event_points';
        _this.secondary_award_description = _this.awards[1].description;
        _this.secondary_award_quantity = (_this.awards[1].type == 'event_points' || _this.awards[1].type == 'card_points' || _this.awards[1].type == 'gift_points' || _this.awards[1].type == 'wallet_points' || _this.awards[1].type == 'digital_points') ? 0 : _this.awards[1].quantity;
        _this.secondary_award_points = _this.awards[1].points;
        _this.secondary_award_boxes = _this.awards[1].boxes;
        _this.secondary_award_alias = _this.awards[0].alias;
      }, 100);
    },
    async updateAwards (award) {
      if(this.main_award_points == 0 || this.main_award_points == '' || this.main_award_points === undefined || this.main_award_points === null){  
        this.showToast("error", "Debes ingresar la cantidad de puntos que se otorgarán al vendedor.");
        return;
      }

      if(award.mechanic == 'customers' && (this.secondary_award_points == 0 || this.secondary_award_points == '' || this.secondary_award_points === undefined || this.secondary_award_points === null)){  
        this.showToast("error", "Debes ingresar la cantidad de puntos que se otorgarán al vendedor.");
        return;
      }

      if(award.mechanic == 'boxes'){
        this.$emit("update", {challenge_id: this.challenge.id, award_id: award.id, alias: this.main_award_alias, description: this.main_award_description, boxes: this.main_award_boxes, quantity: this.main_award_quantity, points: this.main_award_points, type: this.main_award_type, mechanic: this.challenge.mechanic, award_mechanic: award.mechanic, challenge: this.challenge, ranges: this.challenge.ranges} );
      }
      else{
        this.$emit("update", {challenge_id: this.challenge.id, award_id: award.id, alias: this.main_award_alias, description: this.secondary_award_description, boxes: this.secondary_award_boxes, quantity: this.secondary_award_quantity, points: this.secondary_award_points, type: this.secondary_award_type, mechanic: this.challenge.mechanic, award_mechanic: award.mechanic, challenge: this.challenge, ranges: this.challenge.ranges} );
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
